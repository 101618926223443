<template>
    <div class="flex-grow ml-2">
        <button type="button" @click="submit()"
                class="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <!-- <button type="button" @click="open()" class="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"> -->
            Prepare
        </button>
        <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div
                    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-bold text-2xl text-white" id="modal-headline">
                                Prepare {{ orderjob.attributes.order_label }}?
                            </h3>
                            <div class="w-full mt-5 p-2 rounded bg-gray-800">
                                <ul>
                                    <li v-for="(line, line_index) in orderjob.attributes.lines" :key="line_index"
                                        class="flex justify-start truncate">
                                        <span :class="{'ml-2': line.addon}">{{
                                                Math.round(line.quantity)
                                            }}x - {{ line.description }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
                            <button type="button" @click="submit()"
                                    class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-base leading-6 font-medium text-white hover:bg-blue-400 focus:outline-none focus:border-blue-600 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Yes, Prepare
                            </button>
                        </span>
                        <span class="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
                            <button type="button" @click="close()"
                                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Close
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['orderjob'],
    data () {
        return {
            active: false,
        };
    },
    methods: {
        open () {
            this.active = true;
        },
        close () {
            this.active = false;
        },
        submit () {
            this.print();

            axios.post('/orderjobs/' + this.orderjob.id + '/prepare').then(() => {
                this.$emit('reload');
                this.close();
            }).catch(() => {
                console.log('error');
            });
        },

        async print () {
            let content = [];

            let emptyLine = {
                "Alignment": "Centred",
                "EndOfLineFlag": true,
                "Text": '',
            };

            content.push(emptyLine);
            content.push(emptyLine);
            content.push(emptyLine);
            content.push(emptyLine);
            content.push(emptyLine);

            for (let i = 0; i < 5; i++) {
                content.push({
                    "CharacterStyle": "Bold",
                    "Alignment": "Left",
                    "EndOfLineFlag": false,
                    "Text": this.orderjob.attributes.order_label,
                });
                content.push({
                    "CharacterStyle": "Bold",
                    "Alignment": "Right",
                    "EndOfLineFlag": true,
                    "Text": this.orderjob.attributes.order_label,
                });
            }

            content.push(emptyLine);


            let transactionLines = this.orderjob.attributes.lines;
            transactionLines.forEach(function (elem) {
                let quantity = Math.round(elem.quantity).toString() + ' x';
                let description = elem.description;

                content.push({
                    "Alignment": "Left",
                    "EndOfLineFlag": false,
                    "Text": quantity,
                });
                content.push({
                    "Alignment": "Right",
                    "EndOfLineFlag": true,
                    "Text": description,
                });
            });

            content.push(emptyLine);
            content.push(emptyLine);
            content.push(emptyLine);
            content.push(emptyLine);
            content.push(emptyLine);
            content.push(emptyLine);

            console.log(content);


            await axios.post('/print', {
                content: content,
                environment: 'test',
            }).then(response => {

                console.log('success', response);

            }).catch(error => {
                console.error('error', error);
            });
        },
    },
};
</script>
